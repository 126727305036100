




























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { login } from "@/auth";


@Component
export default class Login extends Vue {
  username = '';
  password = '';
  error = false;

  async login() {
    try {
      await login(this.username, this.password);
      this.$router.push('/')
    } catch (e) {
      if (e.response.status === 401) {
        this.error = true;
      }
    }
  }
}
